import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import React, { memo } from 'react'

export interface Props {
  claim?: string
  cta?: ButtonProps
  description?: string
  title?: string
}

export const Heading = memo(function Heading({
  claim,
  cta,
  description,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper>
        <Left>
          {claim ? (
            <FadeInUp>
              <Claim>{claim}</Claim>
            </FadeInUp>
          ) : null}
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
        </Left>
        <Right>
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
          {cta ? (
            <FadeInUp>
              <StyledButton {...cta} />
            </FadeInUp>
          ) : null}
        </Right>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 10rem 8.938vw 0;

  @media (max-width: 1199px) {
    margin: 5.625rem 1.5625rem 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  max-width: 82.125rem;
  margin: 0 auto;

  @media (max-width: 1199px) {
    flex-direction: column;
  }
`

const Left = styled.div`
  width: 50%;
  max-width: 33.125rem;
  margin-right: 9.0625rem;

  @media (max-width: 1600px) {
    margin-right: 9.063vw;
  }

  @media (max-width: 1199px) {
    width: 100%;
    margin-right: 0;
  }
`

const Right = styled.div`
  width: 50%;
  max-width: 34.0625rem;
  margin-top: 1.875rem;

  @media (max-width: 1199px) {
    width: 100%;
    max-width: 100%;
  }
`

const Claim = styled.div`
  margin-bottom: 0.6875rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 3.125rem;
  letter-spacing: -0.05em;
  line-height: 3.4375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 0.02em;
  line-height: 1.8125rem;
`

const StyledButton = styled(Button)`
  margin-top: 2.5rem;

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }
`
