import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  bookURL?: string
  claim?: string
  image?: ImageProps
  isCurrentSlide?: boolean
  languageCode: string
  title?: string
}

export const Item = memo(function Item({
  bookURL,
  claim,
  image,
  isCurrentSlide,
  languageCode,
  title,
}: Props) {
  return (
    <Container className={isCurrentSlide ? 'current' : undefined}>
      {image ? (
        <LazyLoadComponent>
          <StyledImage {...image} />
        </LazyLoadComponent>
      ) : null}
      <Inner>
        {title ? <Title>{title}</Title> : null}
        {claim ? <Claim>{claim}</Claim> : null}
        {bookURL ? (
          <StyledButton
            URL={bookURL}
            target="_blank"
            label={useVocabularyData('book', languageCode)}
          />
        ) : null}
      </Inner>
    </Container>
  )
})

const StyledImage = styled(Image)`
  position: relative;
  display: block;
  width: 100%;
  max-width: 26.125rem;
  max-height: 34.375rem;
  aspect-ratio: 418/550;
  transition: max-height 0.25s ease-in-out 0.05s;

  @media (max-width: 768px) {
    max-width: 16.5625rem;
    max-height: 21.875rem;
    aspect-ratio: 265/350;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.4375rem;
  min-height: 10rem;

  @media (max-width: 1199px) {
    margin-top: 1.25rem;
  }

  @media (max-width: 768px) {
    min-height: 11rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 1.875rem;
  line-height: 2rem;
  text-transform: uppercase;
`

const Claim = styled.div`
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  text-transform: uppercase;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease-in-out 0.05s,
    visibility 0.25s ease-in-out 0.05s;
`

const StyledButton = styled(Button)`
  margin-top: 1.875rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease-in-out 0.05s,
    visibility 0.25s ease-in-out 0.05s;

  @media (max-width: 768px) {
    margin-top: 1.25rem;
  }
`

const Container = styled.div`
  margin-bottom: -6rem;

  @media (max-width: 768px) {
    margin-bottom: -5rem;
  }

  @media (min-width: 769px) {
    &:hover ${StyledImage} {
      max-height: 25rem;
    }

    &:hover ${Claim}, &:hover ${StyledButton} {
      opacity: 1;
      visibility: visible;
    }
  }

  @media (max-width: 768px) {
    &.current ${StyledImage} {
      max-height: 15rem;
    }

    &.current ${Claim}, &.current ${StyledButton} {
      opacity: 1;
      visibility: visible;
    }
  }
`
