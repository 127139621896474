import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

export interface Props {
  cta?: ButtonProps
  claim?: string
  description?: string
  image?: ImageProps
  title?: string
}

export const Block = memo(function Block({
  cta,
  claim,
  description,
  image,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {image ? <StyledImage {...image} /> : null}
        <Inner>
          {claim ? (
            <FadeInUp>
              <Claim>{claim}</Claim>
            </FadeInUp>
          ) : null}
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
          {cta ? (
            <FadeInUp>
              <StyledButton {...cta} />
            </FadeInUp>
          ) : null}
        </Inner>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 10rem 8.938vw 0;

  @media (max-width: 1199px) {
    margin: 5.625rem 1.5625rem 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 82.125rem;
  margin: 0 auto;

  @media (max-width: 992px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    align-items: flex-start;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  width: 50%;
  max-width: 33.125rem;
  aspect-ratio: 530/600;
  margin-right: 9.0625rem;

  @media (max-width: 1600px) {
    margin-right: 9.063vw;
  }

  @media (max-width: 1199px) {
    margin-right: 6.25vw;
  }

  @media (max-width: 992px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.875rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    opacity: 0.15;
  }
`

const Inner = styled.div`
  width: 50%;
  max-width: 30.625rem;

  @media (max-width: 992px) {
    width: 100%;
    max-width: 100%;
  }
`

const Claim = styled.div`
  margin-bottom: 0.6875rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  text-transform: uppercase;

  @media (max-width: 992px) {
    text-align: center;
  }

  @media (max-width: 768px) {
    text-align: left;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 3.125rem;
  letter-spacing: -0.05em;
  line-height: 3.4375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }

  @media (max-width: 992px) {
    text-align: center;
  }

  @media (max-width: 768px) {
    text-align: left;
  }
`

const Description = styled.div`
  margin-top: 2.625rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 0.02em;
  line-height: 1.8125rem;

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }

  @media (max-width: 992px) {
    text-align: center;
  }

  @media (max-width: 768px) {
    text-align: left;
  }
`

const StyledButton = styled(Button)`
  margin-top: 2.5rem;

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }

  @media (max-width: 992px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
`
