import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
}

export const RoomsSlider = memo(function RoomsSlider({ items }: Props) {
  if (!items) {
    return null
  }
  if (items.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)

  const [sliderRef, galleryRef] = useKeenSlider({
    loop: items.length > 1 ? true : false,
    slides: {
      perView: 'auto',
      origin: 'auto',
      spacing: 30,
    },
    breakpoints: {
      '(max-width: 1920px)': {
        slides: {
          perView: 'auto',
          origin: 'center',
          spacing: 30,
        },
      },
      '(max-width: 768px)': {
        slides: {
          perView: 'auto',
          origin: 'auto',
          spacing: 30,
        },
      },
    },
    created(s) {
      setCurrentSlide(s.track.details.rel)
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
  })

  return (
    <Container>
      <FadeInUp>
        <Slider ref={sliderRef} className="keen-slider">
          {items.map((item, index) => (
            <Slide className="keen-slider__slide grab-cursor" key={index}>
              <Item {...item} isCurrentSlide={index === currentSlide} />
            </Slide>
          ))}
          {items.length > 2 ? (
            <Arrows row space="between">
              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || galleryRef.current?.prev()
                }
              />
              <Arrow
                direction="R"
                onClick={(e) =>
                  e.stopPropagation() || galleryRef.current?.next()
                }
              />
            </Arrows>
          ) : null}
        </Slider>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  margin-top: 4.375rem;

  @media (max-width: 1199px) {
    margin-top: 4.0625rem;
  }

  @media (max-width: 768px) {
    margin-left: 1.5625rem;
  }
`

const Slider = styled.div`
  width: 100%;
`

const Slide = styled.div`
  min-width: 26.125rem;

  @media (max-width: 768px) {
    min-width: 16.5625rem;
  }
`

const Arrows = styled(FlexBox)`
  position: absolute;
  top: 40%;
  right: 3.625rem;
  left: 3.625rem;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 3;

  @media (max-width: 768px) {
    display: none;
  }
`
