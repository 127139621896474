import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Syncrobox } from 'app/components/Common/Header/Syncrobox'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'
import { useInView } from 'react-intersection-observer'

interface ImagesProps {
  description?: string
  image?: ImageProps
}

export interface Props {
  images: ImagesProps[]
  languagePrefix?: string
}

export const HeroSlider = memo(function HeroSlider({
  images,
  languagePrefix,
}: Props) {
  if (!images) {
    return null
  }
  if (images?.length < 1) {
    return null
  }

  const [animObjectRef, isAnimObjectVisible] = useInView({ triggerOnce: true })
  const [currentSlide, setCurrentSlide] = useState(0)
  const [opacities, setOpacities] = useState<number[]>([])

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: images.length > 1 ? true : false,
    slides: images.length,
    drag: false,
    detailsChanged(s) {
      const new_opacities = s.track.details.slides.map((slide) => slide.portion)
      setOpacities(new_opacities)
    },
  })

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Slider ref={sliderRef} className="keen-slider fader">
          {images.map((item, index) => (
            <Slide
              className={
                currentSlide === index ? 'fader__slide current' : 'fader__slide'
              }
              key={index}
              style={{ opacity: opacities[index] }}
            >
              <StyledImage {...item.image} />
            </Slide>
          ))}
        </Slider>

        <Wrapper
          row={false}
          dial={5}
          ref={animObjectRef}
          className={isAnimObjectVisible ? 'visible' : undefined}
        >
          <Logo
            src="/logo_hero.svg"
            alt="Logo Mormoraia"
            width={153}
            height={95}
          />
          <Claims>
            {images.map((item, index) => (
              <Claim
                className={currentSlide === index ? 'current' : undefined}
                key={index}
                onClick={() => {
                  instanceRef.current?.moveToIdx(index)
                  setCurrentSlide(index)
                }}
              >
                {item.description}
                <Icon src="hero_icon.svg" alt="Icon" width={231} height={31} />
              </Claim>
            ))}
          </Claims>

          <Media greaterThanOrEqual="desktopSmall">
            <Syncrobox languagePrefix={languagePrefix || 'IT'} />
          </Media>
        </Wrapper>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  width: 100%;
  height: 100vh;
`

const Slider = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`

const Slide = styled.div`
  &.current img {
    animation: kenburns 3s ease-in-out;

    @-webkit-keyframes kenburns {
      0% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      100% {
        -webkit-transform: scale(1.06);
        transform: scale(1.06);
      }
    }
    @keyframes kenburns {
      0% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      100% {
        -webkit-transform: scale(1.06);
        transform: scale(1.06);
      }
    }
  }
`

const StyledImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transform: scale(1.06);
    transform: scale(1.06);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    opacity: 0.2;
  }
`

const Wrapper = styled(FlexBox)`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateY(-50%);
  transition: 0.6s 0.2s ease-in-out;

  &.visible {
    opacity: 1;
    transform: translateY(-50%);
  }
`

const Logo = styled.img`
  position: absolute;
  top: 5.33vh;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 768px) {
    position: static;
    transform: none;
  }
`

const Claims = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5vh;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 3.75rem;
  }
`

const Icon = styled.img`
  max-height: 1.9375rem;
  margin-top: 2.625rem;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;

  @media (max-width: 1199px) {
    width: 9.8125rem;
    height: 1.3125rem;
    max-height: 1.3125rem;
    margin-top: 0.75rem;
  }
`

const Claim = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 5rem;
  line-height: 2.8125rem;
  text-transform: uppercase;
  opacity: 0.6;
  transition: opacity 0.25s ease-in-out;
  cursor: pointer;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
  }

  &:not(:first-of-type) {
    margin-left: 5rem;

    @media (max-width: 1199px) {
      margin-left: 3.375rem;
    }

    @media (max-width: 768px) {
      margin-left: 0;
      margin-top: 2rem;
    }
  }

  &.current {
    opacity: 1;

    ${Icon} {
      opacity: 1;
    }
  }
`
